//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// import IconGame from '~/assets/svg/game.svg?inline'

export default {
  // components: { IconGame },

  data() {
    return {
      charm360URL: process.env.charm360URL,
      charm360URLGuest: `/auth?redirectTo=${process.env.charm360URL}`,
    }
  },
}
