// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/img/myth-fact-quiz/banner-bg.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../assets/img/myth-fact-quiz/banner-bg-2x.png");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../../assets/img/myth-fact-quiz/banner-bg-md.png");
var ___CSS_LOADER_URL_IMPORT_3___ = require("../../assets/img/myth-fact-quiz/banner-bg-md-2x.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
exports.push([module.id, ".banner[data-v-4973baa4]{min-height:16.125rem;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat 100% 100%/auto 100% #bce1fc}@media only screen and (-webkit-min-device-pixel-ratio:2),only screen and (min-device-pixel-ratio:2),only screen and (min-resolution:2dppx),only screen and (min-resolution:192dpi){.banner[data-v-4973baa4]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ")}}@media(min-width:992px){.banner[data-v-4973baa4]{min-height:13.875rem;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ");background-position:100% 0}}@media only screen and (min-width:992px)and (-webkit-min-device-pixel-ratio:2),only screen and (min-width:992px)and (min-device-pixel-ratio:2),only screen and (min-width:992px)and (min-resolution:2dppx),only screen and (min-width:992px)and (min-resolution:192dpi){.banner[data-v-4973baa4]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ")}}@media(min-width:992px){.text[data-v-4973baa4]{max-width:57.4074074074%}}", ""]);
// Exports
module.exports = exports;
