import { parse } from 'cookie'

export function setCookies(name, value, path = '/', expires) {
  document.cookie = `${name}=${value}; path=${path}; ${
    expires ? `expires=${expires}` : ''
  }`
}

export function removeCookies(name, path = '/') {
  document.cookie = `${name}=; path=${path}; max-age=0`
}

export function getCookies() {
  const cookieStr = process.client
    ? document.cookie
    : this._context.req.headers.cookie
  const cookies = parse(cookieStr || '')

  return cookies
}
