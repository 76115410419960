//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import hasVideos from '../../mixins/hasVideos'
import hasWindow from '../../mixins/hasWindow'

export default {
  mixins: [hasVideos, hasWindow],

  computed: {
    videosFiltered() {
      const videos = this.getVideos(this.videosParams)
      return this.breakpointUp('md') ? videos : videos.slice(0, 2)
    },

    videosParams() {
      const params = {
        roleCode: this.$auth.contentRole,
        orderBy: 'published_at',
        limit: 4,
      }

      if (this.$auth.user) params.role_id = this.$auth.user.role_id

      return params
    },
  },

  watch: {
    videosParams: {
      handler() {
        this.fetchVideos(this.videosParams)
      },
      immediate: true,
    },
  },
}
