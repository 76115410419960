export function formatDate(articleDate) {
  const listmonths = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ]
  const date = new Date(articleDate)
  let day = date.getDate()
  const month = listmonths[date.getMonth()]
  const year = date.getFullYear()
  if (day < 10) {
    // @ts-ignore
    day = '0' + day
  }

  const today = day + ' ' + month + ' ' + year

  return today
}

export function formatNumber(value) {
  if (!value) return '0'
  return value.toLocaleString()
}
