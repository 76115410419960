//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Swiper, { Navigation, Pagination, Autoplay } from 'swiper'
import hasWindow from '~/mixins/hasWindow'
import IconArrowLeftCircle from '~/assets/svg/arrow-left-circle.svg?inline'
import IconArrowRightCircle from '~/assets/svg/arrow-right-circle.svg?inline'
import { generateImageURL } from '~/utils/storage'

Swiper.use([Navigation, Pagination, Autoplay])

export default {
  components: { IconArrowLeftCircle, IconArrowRightCircle },
  mixins: [hasWindow],

  props: ['banners'],

  data() {
    return { selectedBanner: this.banners[0] }
  },

  mounted() {
    this.initSwiper()
  },

  beforeDestroy() {
    this.swiper && this.swiper.destroy()
  },

  methods: {
    generateImageURL,
    initSwiper() {
      this.swiper = new Swiper(this.$el, {
        pagination: { el: '.swiper-pagination' },
        loop: true,
        speed: 400,
        autoplay: {
          delay: 3000,
        },
        disableOnInteraction: true,
        resistanceRatio: 0,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      })
    },
  },
}
