// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/img/360/maximix-web.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../assets/img/360/maximix-mobile.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, ".banner[data-v-5f989618]{position:relative;z-index:0;min-height:16.125rem;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat 100% 100%/13.5rem #fedee8}@media(max-width:991.98px){.banner[data-v-5f989618]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ")!important}}@media only screen and (-webkit-min-device-pixel-ratio:2),only screen and (min-device-pixel-ratio:2),only screen and (min-resolution:2dppx),only screen and (min-resolution:192dpi){.banner[data-v-5f989618]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:cover;background-repeat:no-repeat;background-position:50%}}@media(min-width:992px){.banner[data-v-5f989618]{min-height:13.875rem;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:auto 100%;background-position:50%}}@media only screen and (min-width:992px)and (-webkit-min-device-pixel-ratio:2),only screen and (min-width:992px)and (min-device-pixel-ratio:2),only screen and (min-width:992px)and (min-resolution:2dppx),only screen and (min-width:992px)and (min-resolution:192dpi){.banner[data-v-5f989618]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-position:50%}}.overlay[data-v-5f989618]{position:absolute;top:0;left:0;width:100%;height:100%;border-radius:13px;background-color:rgba(0,0,0,.8);color:#fff;text-align:center;margin-left:auto;margin-right:auto;right:0;z-index:1;font-weight:700;font-size:28px}.overlay .content[data-v-5f989618]{width:100%;height:100%;display:flex;justify-content:center;align-items:center}@media(min-width:992px){.text[data-v-5f989618]{max-width:57.4074074074%}}", ""]);
// Exports
module.exports = exports;
