//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Swiper, { Autoplay, Navigation, Pagination } from 'swiper'

Swiper.use([Navigation, Pagination, Autoplay])

export default {
  name: 'HomeProductCategory',
  data() {
    return {
      images: [
        {
          src: '/images/home/product-banner-01.jpg',
          alt: 'product-banner-01',
          link: '/produk/kategori/6/body-fit',
        },
        {
          src: '/images/home/product-banner-02.jpg',
          alt: 'product-banner-02',
          link: '/produk/kategori/3/safe-night',
        },
        {
          src: '/images/home/product-banner-03.jpg',
          alt: 'product-banner-03',
          link: '/produk/kategori/4/cooling-fresh',
        },
        {
          src: '/images/home/product-banner-04.jpg',
          alt: 'product-banner-04',
          link: '/produk/kategori/34/charm-herbal-ansept',
        },
      ],
    }
  },
  mounted() {
    this.swiper = new Swiper('.swiper-container', {
      loop: true,
      speed: 400,
      autoplay: {
        delay: 3000,
      },
      spaceBetween: 16,
      slidesPerView: 1,
      slidesPerColumnFill: 'row',
    })
  },
}
