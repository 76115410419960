//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Swiper from 'swiper'

import GamificationBanner from '~/components/gamification/GamificationBanner'
import MythFactQuizBanner from '~/components/myth-fact-quiz/MythFactQuizBanner'

export default {
  computed: {
    banners() {
      if (this.$auth.isGirl) {
        return [GamificationBanner, MythFactQuizBanner]
      }

      return [MythFactQuizBanner, GamificationBanner]
    },
  },

  mounted() {
    this.initSwiper()
  },

  methods: {
    initSwiper() {
      this.swiper = new Swiper(this.$el.querySelector('.swiper-container'), {
        freeMode: true,
        resistanceRatio: 0,
        slidesPerView: 'auto',
      })
    },
  },
}
