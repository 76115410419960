//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import hasVideos from '~/mixins/hasVideos'
import youTubeIframeAPI from '~/mixins/youTubeIframeAPI'
import Video from '~/models/Video'

import IconCheckmark from '~/assets/svg/checkmark.svg?inline'

export default {
  components: { IconCheckmark },
  mixins: [hasVideos, youTubeIframeAPI],

  props: {
    video: { type: Object, default: null },
    // Function to wait before allowed to play video.
    waitToPlay: { type: Function, default: () => {} },
  },

  data() {
    return this.getInitialState()
  },

  methods: {
    getInitialState() {
      return { isLoaded: false, isLoading: false }
    },

    async initPlayer() {
      const onLoaded = () => {
        this.isLoaded = true
        this.isLoading = false

        if (this.player.getPlayerState() === window.YT.PlayerState.PAUSED) {
          this.player.playVideo()
        }
      }
      const onStateChange = (event) => {
        switch (event.data) {
          case window.YT.PlayerState.ENDED:
            this.maybeMarkAsWatched()
            break
        }
      }

      this.isLoading = true

      await Promise.resolve(this.waitToPlay())

      if (this.player) {
        onLoaded()
        return
      }

      await this.youTubeIframeAPIReady()

      const wrapper = this.$el.querySelector('.img')
      const player = wrapper.querySelector('.embed-responsive-item')
      this.player = new window.YT.Player(player, {
        height: wrapper.offsetHeight,
        width: wrapper.offsetWidth,
        videoId: this.video.youtube_id,
        playerVars: { autoplay: 1 },
        events: {
          onReady: onLoaded,
          onStateChange,
          onError: onLoaded,
        },
      })
    },

    maybeMarkAsWatched() {
      if (this.video.user_point || !this.$auth.isLoggedIn) return
      this.markAsWatched()
    },

    async markAsWatched() {
      try {
        const response = await this.$axios.post(
          `v1/videos/${this.video.id}/watch`
        )
        const video = new Video(response.data.data)

        this.updateVideo(video)
      } catch (error) {
        console.error(error)
      }
    },

    reset() {
      this.player && this.player.pauseVideo && this.player.pauseVideo()
      Object.assign(this, this.getInitialState())
    },
  },
}
