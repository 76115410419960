//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import get from 'lodash/get'
import readingTime from 'reading-time'
import hasArticles from '~/mixins/hasArticles'
import { generateImageURL } from '~/utils/storage'
import { formatDate } from '~/utils/formatter'
import Article from '~/models/Article'
import hasWindow from '~/mixins/hasWindow'

export default {
  mixins: [hasArticles, hasWindow],

  data() {
    return {
      popularArticles: [],
      pinnedArticles: [],
      orderByData: 'published_at',
    }
  },

  computed: {
    popularArticleFiltered() {
      const tempArticles = this.popularArticles.filter((x, i) => i > 1)
      return this.breakpointUp('md')
        ? tempArticles
        : tempArticles.filter((x, i) => i > 1)
    },
  },

  watch: {
    '$route.query'() {
      this.loadData()
    },
  },
  beforeMount() {
    this.loadData()
  },

  methods: {
    get,
    async loadData() {
      try {
        const response = await this.$axios.get(`v1/pinned-article`, {
          params: {
            page: 1,
            limit: 6,
            category_id: this.$route.query.category_id,
          },
        })
        this.popularArticles = response.data.data.map(
          (article) => new Article(article)
        )
      } catch (e) {}
    },
    generateImage(url) {
      return `${generateImageURL(url || '')}?&fit=crop`
    },
    getContent(text) {
      return `${text.replace(/<[^>]+>/g, '')}`
    },
    getDate(date) {
      return `${formatDate(date)}`
    },
    getMinutes(min) {
      return min || 0
    },
    getTotalComment(comment) {
      return comment || 0
    },
    getReadingTime(content) {
      return readingTime(content).text
    },
    getUrl(url) {
      return url.replace(/[^a-zA-Z ]/g, '')
    },
  },
}
