//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import IconMythFact from '~/assets/svg/myth-fact.svg?inline'

export default {
  components: { IconMythFact },
}
