//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import HomeBannerSlider from '~/components/banner/HomeBannerSlider'
import { getCookies, removeCookies } from '~/utils/cookies'
import hasBanners from '~/mixins/hasBanners'
import HomeProductCategory from '~/components/home/HomeProductCategory'
import HomePopularArticles from '~/components/home/HomePopularArticles'
import HomeVideos from '~/components/home/HomeVideos'
import HomeActivity from '~/components/home/HomeActivity'

export default {
  components: {
    HomeActivity,
    HomeProductCategory,
    HomePopularArticles,
    HomeVideos,
    HomeBannerSlider,
  },

  mixins: [hasBanners],

  computed: {
    bannersParams() {
      return { orderBy: ['priority', 'id'], limit: 5, isActive: true }
    },
  },

  mounted() {
    const cookies = getCookies()
    const isShowCompleteData = Number(cookies.show_complete_data)
    if (isShowCompleteData) {
      this.$modalCompleteData.show()
      removeCookies('show_complete_data')
    }
  },

  beforeMount() {
    this.fetchBanners(this.bannersParams)
  },

  head() {
    return {
      title:
        'Charmgirlstalk - Artikel Seputar Menstruasi dan Masalah Kewanitaan',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content:
            'Tempatnya membahas semua hal tentang menstruasi dan seputar cewek dengan cara FUN! Tersedia dalam dua bagian, yaitu untuk orang tua dan untuk anak perempuan.',
        },
      ],
    }
  },
}
