import get from 'lodash/get'
import { createResourceMixin } from '../utils/store'
import Video from '~/models/Video'

const mixin = createResourceMixin('videos', 'video')

export default {
  ...mixin,

  data() {
    return { isLoadingVideos: false, lastVideosMeta: null, videos: [] }
  },

  computed: {
    ...mixin.computed,

    videosParams() {
      return { roleCode: this.$auth.contentRole, orderBy: 'published_at' }
    },
  },

  methods: {
    get,
    ...mixin.methods,

    async loadMoreVideos() {
      if (
        this.lastVideosMeta &&
        this.lastVideosMeta.current_page === this.lastVideosMeta.last_page
      ) {
        return
      }

      try {
        this.isLoadingVideos = true
        const page = this.lastVideosMeta && this.lastVideosMeta.current_page + 1
        const params = { ...this.videosParams, page }

        const response = await this.$axios.get(`v1/videos`, {
          params,
        })

        const videos = response.data.data.map((video) => new Video(video))
        const concatVideos = this.videos.concat(videos)
        this.videos = [...new Set(concatVideos)]
        this.lastVideosMeta = get(response.data.meta)
      } finally {
        this.isLoadingVideos = false
      }
    },
  },
}
