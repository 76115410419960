//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import hasWindow from '../../mixins/hasWindow'
import hasGalleries from '~/mixins/hasGalleries'
import GalleryItemVideo from '~/components/gallery/GalleryItemVideo'
import GalleryItemImage from '~/components/gallery/GalleryItemImage'
import Gallery from '~/models/Gallery'

export default {
  components: { GalleryItemVideo, GalleryItemImage },
  mixins: [hasGalleries, hasWindow],

  data() {
    return {
      Gallery,
      galleries: [],
      params: { limit: 4, page: 1, orderBy: 'priority' },
    }
  },

  computed: {
    galleriesFiltered() {
      const galleries = this.getGalleries(this.params)
      return this.breakpointUp('md') ? galleries : galleries.slice(0, 2)
    },
  },

  beforeMount() {
    this.loadData()
  },

  methods: {
    async loadData() {
      try {
        await this.fetchGalleries(this.params)
        this.galleries = this.getGalleries(this.params)
      } finally {
      }
    },
  },
}
