import { render, staticRenderFns } from "./HomeVideos.vue?vue&type=template&id=c113c562&"
import script from "./HomeVideos.vue?vue&type=script&lang=js&"
export * from "./HomeVideos.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {VideoItem: require('/var/www/charm-app/components/video/VideoItem.vue').default,HomeGamification: require('/var/www/charm-app/components/home/HomeGamification.vue').default})
