//
//
//
//
//

import { escAttr } from '../../utils/string'

export default {
  props: {
    content: { type: String, default: '' },
    defaultImgAlt: { type: String, default: '' },
  },

  computed: {
    parsedContent() {
      let content = this.content
      content = this.addDefaultImgAlt(content)

      return content
    },
  },

  mounted() {
    this.addNbspClass()
  },

  methods: {
    addDefaultImgAlt(content) {
      if (!content) {
        return ''
      }

      return content.replace(/<img.*?>/gi, (img) => {
        let result = img
        if (!result.match(/alt=/i)) {
          result = result.replace(
            /<img/i,
            `<img alt="${escAttr(this.defaultImgAlt)}"`
          )
        }

        return result
      })
    },

    addNbspClass() {
      this.$el.querySelectorAll('p').forEach((p) => {
        if (p.innerHTML.trim() === '&nbsp;') {
          p.classList.add('nbsp')
        }
      })
    },
  },
}
